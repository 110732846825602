import React from "react";
import { ContactForm } from "../sections/";
import { Layout, SEO } from "../components";
import { injectIntl, useIntl, FormattedMessage } from "gatsby-plugin-intl";
import * as Scroll from "react-scroll";
import ContactIcon from "../images/contactIcon.svg";
import styled from "styled-components";
import { withLineBreaks } from "../utils/withLineBreaks";

const Wrapper = styled.div`
  padding: 0 24px;
  display: flex;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  min-height: 100vh;
  position: relative;
  > div {
    display: flex;
    flex-direction: column;
    width: 700px;
  }
  svg {
    margin: 0 auto;
    height: 300px;
    max-width: 400px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    svg {
      display: none;
    }
    > div {
      padding-left: 0vw;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 23px;
  color: inherit;
  font-weight: 400;
  font-size: 48px;
  line-height: 68px;
  @media (max-width: 900px) {
    margin-top: 150px;
    font-size: 48px;
    font-size: 36px;
    line-height: 44px;
  }
`;

function Contact() {
  const intl = useIntl();
  const t = x => {
    return intl.formatMessage({ id: x });
  };
  return (
    <Layout>
      <SEO title={t("contact_seo_title")} />
      <Scroll.Element name="contact">
        <Wrapper>
          <div>
            <Title>
              <FormattedMessage id="contact_form_title">
                {withLineBreaks}
              </FormattedMessage>
            </Title>
            <ContactIcon />
          </div>
          <ContactForm />
        </Wrapper>
      </Scroll.Element>
    </Layout>
  );
}

export default injectIntl(Contact);
